import * as React from 'react'
import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews'


import linkResolver from '../utils/linkResolver'

const PreviewPage = ({ isPreview }) => {
    if (isPreview === false) return 'Not a preview!'

    return <p>Loading</p>
}


export default withPrismicPreviewResolver(PreviewPage, [
    {
        repositoryName: "taxomate-blog",
        linkResolver,
    },
])
